import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction, useProductTabContent } from 'Common/hooks';
import { ProductDescriptionTab, ProductTabContent } from 'Common/components/product';
import { product, order } from '~features';
import * as GTM from 'Common/constants/gtm';

export default function TabContent({
    className,
    DescriptionTab = ProductDescriptionTab,
    pageId,
    specsTableBreakpoint,
    statusTooltip,
    isUserAuthenticated,
}) {
    const options = useSelector(product.selectors.getOptions);
    const productInfo = useSelector(product.selectors.getProduct);
    const productDefinitions = useSelector(product.selectors.getProductDefinitions);
    const onAddItemToCart = useAction(order.actions.addItem);
    const orderLastUpdate = useSelector(order.selectors.getLastUpdate);
    const totalQty = useSelector(order.selectors.getTotalQuantity);
    const displayOnly = React.useMemo(() => productInfo?.StockStatus === 'DisplayOnly', [productInfo]);
    const tabContent = useProductTabContent();

    return tabContent.length ? (
        <ProductTabContent
            className={className}
            DescriptionTab={DescriptionTab}
            displayOnly={displayOnly}
            gtmListValue={GTM.TAGS.PDP}
            isUserAuthenticated={isUserAuthenticated}
            onAddItemToCart={onAddItemToCart}
            options={options}
            orderLastUpdate={orderLastUpdate}
            pageId={pageId}
            productDefinitions={productDefinitions}
            productInfo={productInfo}
            specsTableBreakpoint={specsTableBreakpoint}
            statusTooltip={statusTooltip}
            tabContent={tabContent}
            totalQty={totalQty}
        />
    ) : null;
}

TabContent.propTypes = {
    className: px.string,
    DescriptionTab: px.elementType,
    pageId: px.string,
    specsTableBreakpoint: px.number,
    statusTooltip: px.oneOf([false, true, undefined, null, 'top', 'bottom', 'right']),
    isUserAuthenticated: px.bool
};
