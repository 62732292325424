import $ from 'jquery';
import { getAntiForgeryToken } from 'Common/utils/Api';

function setLoading(uid, isLoading) {
    if (isLoading) {
        $(`#${uid} > .LoaderComponent`).removeAttr('style');
        $(`#${uid} .pointsRedemptionForm input, #${uid} .pointsRedemptionForm button`).attr('disabled', 'disabled');
    } else {
        $(`#${uid} > .LoaderComponent`).attr('style', 'display: none');
        $(`#${uid} .pointsRedemptionForm button`).removeAttr('disabled');
    }
}

function repopulateRadioButtons(redemptionOptions = []) {
    redemptionOptions.forEach((option) => {
        const input = $(`#${option.Value}`);

        if (option.Selected) input.attr('checked', 'checked');
        else input.removeAttr('checked');

        input.attr('disabled', option.Disabled);
    });
}

export default class RewardsController {
    static onPointsRedeeming(uid, xhr) {
        xhr.setRequestHeader('RequestVerificationToken', getAntiForgeryToken());
        setLoading(uid, true);
    }

    static onPointsRedemption(uid, response = {}) {
        const { message, redemptionOptions, currentPointsText } = response;

        $(`#${uid} .pointsRedemptionForm`)[0].reset();
        $(`#${uid}-message .resultMessage`).removeClass('d-none');
        $(`#${uid}-message .resultMessage`).html(message);
        repopulateRadioButtons(redemptionOptions);
        $(`#${uid} .currentPointsTotal`).html(currentPointsText);
        setLoading(uid, false);
    }

    static validateForm(uid) {
        const { value } = $(`#${uid} .pointsRedemptionForm`)[0]['redemptionOptionId'];

        if (value) {
            $(`#${uid} .pointsRedemptionForm button[type=submit]`).removeAttr('disabled');
        } else {
            $(`#${uid} .pointsRedemptionForm button[type=submit]`).attr('disabled', 'disabled');
        }
    }
}
