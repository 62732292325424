import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import $ from 'jquery';
import { useTranslation } from 'Common/hooks';
import { Translation } from 'Common/components/localization';

function Container({ children, enabled, className, label, onClick }) {
    const onKeyPress = React.useMemo(() => (onClick ? (e) => (e.key === 'Enter' ? onClick() : null) : null), [onClick]);

    return enabled && onClick ? (
        <div
            className={cx('ProductRating', className)}
            role="button"
            aria-label={label}
            tabIndex="1"
            onClick={onClick}
            onKeyPress={onKeyPress}
        >
            {children}
        </div>
    ) : (
        <div className={cx('ProductRating', className)}>{children}</div>
    );
}

Container.propTypes = {
    className: px.string,
    children: px.node,
    enabled: px.bool,
    label: px.string,
    onClick: px.func,
};

export default function ProductRating({
    className,
    reviewCount = 0,
    center = true,
    slimView = false,
    reviewProviderEntityId,
    writeReviewAnchor,
    isDiscontinued,
    leftAlignBreakpoint = 'lg',
    onGotoReviewSection,
    forceInline = false,
    hideLink = false,
}) {
    const reviewQtySingular = useTranslation('Commerce.Product.ReviewQuantity.Singular.Label');
    const reviewQtyPlural = useTranslation('Commerce.Product.ReviewQuantity.Plural.Label');
    const reviewsLabel = React.useMemo(
        () => `${reviewCount} ${reviewCount === 1 ? reviewQtySingular : reviewQtyPlural}`,
        [reviewCount, reviewQtySingular, reviewQtyPlural]
    );

    const goToReviews = React.useMemo(
        () =>
            onGotoReviewSection ??
            ((write = false) => {
                const accordionContent = $(`#${writeReviewAnchor}`);
                const accordionButton = $(`#${writeReviewAnchor}__button`);

                if (!accordionContent?.attr('aria-expanded')) accordionButton.attr('aria-expanded', true);
                if (!accordionContent.hasClass('show')) accordionContent.addClass('show');
                if (accordionButton?.hasClass('collapsed')) accordionButton.removeClass('collapsed');
                if (write) $('.write-review-wrapper')?.css('display', 'block');
                document.getElementById(writeReviewAnchor)?.scrollIntoView();
            }),
        [writeReviewAnchor, onGotoReviewSection]
    );

    const onWriteReview = React.useCallback(
        (e) => {
            e.stopPropagation();
            goToReviews(true);
        },
        [goToReviews]
    );

    const onGotoReviews = React.useCallback(() => goToReviews(), [goToReviews]);

    React.useEffect(() => {
        if (window.yotpo?.initialized) {
            window.yotpo.refreshWidgets();
        }
    }, [reviewProviderEntityId]);

    return (
        <Container
            className={cx({ feedback: !slimView }, className)}
            onClick={onGotoReviews}
            label={reviewsLabel}
            enabled
        >
            {slimView ? (
                <div className={cx(center && 'ProductRating__BottomLine align-items-center justify-content-center')}>
                    <div className="yotpo bottomLine" data-yotpo-product-id={reviewProviderEntityId}></div>
                </div>
            ) : (
                <div className="ProductRating__BottomLine row pt-2 d-flex justify-content-between">
                    <div
                        className={`ProductRating__BottomLine__Stars p-0 d-flex align-items-center justify-content-center justify-content-${leftAlignBreakpoint}-start`}
                    >
                        <div className="yotpo bottomLine" data-yotpo-product-id={reviewProviderEntityId}></div>
                    </div>
                    {hideLink ? null : (
                        <div
                            className={`ProductRating__BottomLine__Link col-${forceInline ? '6' : '12'} col-lg-6 ${
                                forceInline ? '' : 'mt-2'
                            } mt-lg-0 d-flex align-items-center justify-content-center justify-content-${leftAlignBreakpoint}-start`}
                        >
                            <div className="row">
                                <p className="paragraph-3 mb-0 strong">
                                    {isDiscontinued || !writeReviewAnchor ? null : (
                                        <span>
                                            <span className="reviewCount"></span>
                                            <span className="reviewSeparator">{'|'}</span>
                                            <a className="writeReview" onClick={onWriteReview}>
                                                <Translation id="Commerce.Product.Link.WriteReview.Label" />
                                            </a>
                                        </span>
                                    )}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Container>
    );
}

ProductRating.propTypes = {
    className: px.string,
    reviewCount: px.number,
    center: px.bool,
    hideLink: px.bool,
    isDiscontinued: px.bool,
    slimView: px.bool,
    reviewProviderEntityId: px.string,
    writeReviewAnchor: px.string,
    forceInline: px.bool,
    leftAlignBreakpoint: px.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    onGotoReviewSection: px.func,
};
