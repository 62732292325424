import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import ProductGallery from './ProductGallery';
import { ProductDetailsComponent as ProductDetails } from '~components/product';
import RelatedProducts from './RelatedProducts';
import ProductTitle from './ProductTitle';
import ProductBadge from './ProductBadge';
import { useViewport } from 'Common/hooks';

export default function ProductView({
    className,
    Details = ProductDetails,
    detailsProps = {},
    Gallery = ProductGallery,
    galleryProps = {},
    isUserAuthenticated,
    Related = RelatedProducts,
    relatedProductProps = {},
    showRelatedProducts = false,
    itemStatusTooltip,
    productTitle,
    currentMarketingBadges,
    isQuickView=false
}) {

    const viewport = useViewport();
    const isMobile = React.useMemo(() => viewport.is.lt(galleryProps.desktopBreakpoint ?? 'md'), [viewport, galleryProps.desktopBreakpoint]);

    return (
        <div className={cx('ProductView container-fluid', className)}>
            {!isMobile && !isQuickView ?(
                <>
                    <div className="ProductView__title">
                        <ProductBadge badges={currentMarketingBadges} />
                        <div className="divider"></div>
                        <ProductTitle productTitle={productTitle} useLargeProductTitle={true} />
                    </div>
                    <div className='ProductView__details'>
                        <Gallery
                            className={cx('col-12', `col-${galleryProps.desktopBreakpoint ?? 'md'}-5`)}
                            {...galleryProps}
                        />
                        <Details
                            className={cx('col-12', `col-${detailsProps.desktopBreakpoint ?? 'md'}-7`, {
                                'col-lg-5': showRelatedProducts,
                            })}
                            isUserAuthenticated={isUserAuthenticated}
                            itemStatusTooltip={itemStatusTooltip}
                            {...detailsProps}
                        />
                        {showRelatedProducts ? <Related {...relatedProductProps} className="d-none d-lg-block col-2" /> : null}
                    </div>
                </>
            ) : (
                    <div className={isQuickView && !isMobile ? 'd-flex' : ''}>
                        <Gallery
                            className={cx('col-12', `col-${galleryProps.desktopBreakpoint ?? 'md'}-5`)}
                            {...galleryProps}
                        />
                        <div className='w-100'>
                            <div className="ProductView__title">
                                <ProductBadge badges={currentMarketingBadges} />
                                <div className="divider"></div>
                                <ProductTitle productTitle={productTitle} useLargeProductTitle={true} />
                            </div>
                            <div className='ProductView__details'>
                                <Details
                                    isUserAuthenticated={isUserAuthenticated}
                                    itemStatusTooltip={itemStatusTooltip}
                                    {...detailsProps}
                                />
                                {showRelatedProducts ? <Related {...relatedProductProps} className="d-none d-lg-block col-2" /> : null}
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
};

ProductView.propTypes = {
    className: px.string,
    Details: px.elementType,
    detailsProps: px.objectOf(px.any),
    galleryProps: px.objectOf(px.any),
    Gallery: px.elementType,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    Related: px.elementType,
    relatedProductProps: px.object,
    showRelatedProducts: px.bool,
    itemStatusTooltip: px.bool,
    productTitle: px.string,
    currentMarketingBadges: px.arrayOf(px.string),
    isQuickView: px.bool,
};
