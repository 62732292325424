import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Translation } from 'Common/components/localization';

const TIMEOUT_MS = 5000;

const CLS = {
    info: 'alert-primary',
    success: 'alert-success',
    error: 'alert-danger',
    warning: 'alert-warning',
};

export default function UserMessage({
    className,
    timestamp = 0,
    message = '',
    children,
    type = 'info',
    timeout = TIMEOUT_MS,
    onClick,
    dontHideWhenClicked = false,
    isGiftMessage = false,
}) {
    const [showMsg, setShowMsg] = React.useState(false);
    const msgTimer = React.useRef(null);
    const lastTimestamp = React.useRef(0);
    const onHideMessage = React.useRef(() => {
        setShowMsg(false);
        clearTimeout(msgTimer.current);
    });

    const onShowMessage = React.useRef(() => {
        setShowMsg(true);
        if (timeout) msgTimer.current = setTimeout(onHideMessage.current, timeout);
        lastTimestamp.current = timestamp;
    });

    const handleClick = React.useCallback(
        (e) => {
            if (onClick) onClick(e);
            if (!dontHideWhenClicked) onHideMessage.current();
        },
        [onClick, dontHideWhenClicked]
    );

    React.useEffect(() => {
        let changed = false;
        const cleanup = () => {
            if (changed) clearTimeout(msgTimer.current);
        };

        if ((message || children) && timestamp > lastTimestamp.current && !isGiftMessage) {
            changed = true;
            onShowMessage.current();
        } else if (!message && !children && timestamp >= lastTimestamp.current) {
            onHideMessage.current();
        }

        return cleanup;
    }, [timestamp, message, children]);

    return (
        <div
            className={cx('UserMessage', showMsg && (message || children) && 'show', className)}
            onClick={handleClick}
            style={{ pointerEvents: showMsg && (message || children) ? 'auto' : 'none' }}
        >
            <div className={cx('alert popup-alert', CLS[type])} role="alert">
                <span>
                    {children || null}
                    {message ? <Translation id={message} /> : null}
                </span>
            </div>
        </div>
    );
}

UserMessage.propTypes = {
    className: px.string,
    timestamp: px.number,
    timeout: px.number,
    message: px.string,
    children: px.node,
    onClick: px.func,
    dontHideWhenClicked: px.bool,
    type: px.oneOf(['info', 'success', 'error', 'warning']),
    isGiftMessage: px.bool,
};
