import px from 'prop-types';
import React, { useMemo } from 'react';
import cx from 'classnames';

/**
 * Returns the properties of the first header row, if it exists.
 * 
 * Returns `null` if the first header row does not exist.
 *  
 * @param {ReactNode} children 
 * @returns Properties for the first header row, if it exists. `null` if it does not exist.
 */
function getHeaderProps(children) {
    return React.Children.map(children, (child) => {
        return child.type === Header ? child.props : null;
    }).filter(Boolean)[0];
}

/**
 * Computes the column widths for a grid based on its header properties.
 * 
 * If `headerProps` is `null`, returns an array with a single element: `['repeat(1fr)']`.
 * @param {*} headerProps 
 * @returns Array of column widths for the grid.
 */
function getColumnWidths(headerProps) {
    if (headerProps == null) return ['repeat(1fr)'];

    return React.Children.map(headerProps.children, (child) => {
        if (child?.type !== Cell) return null;
        return child?.props?.width ?? '1fr';
    }).filter(Boolean);
}

export default function Grid({ children, className, style, ...props }) {
    const headerProps = getHeaderProps(children);
    const columnWidths = getColumnWidths(headerProps);
    const templateColumns = columnWidths.join(' '); 

    return (
        <div className={cx('Grid', className)} style={{ ...style, gridTemplateColumns: templateColumns }} {...props}>
            {React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                    ...child.props,
                    index,
                })
            )}
        </div>
    );
}

Grid.propTypes = {
    children: px.node,
    className: px.string,
};

export function Header({ children, index, ...props }) {
    return React.Children.map(children, (child, columnIndex) =>
        child?.type === Cell
            ? React.cloneElement(child, {
                ...props,
                header: true,
                rowIndex: index,
                columnIndex,
            }) 
            : child
    );
}

Header.propTypes = {
    children: px.node,
    index: px.number,
};

export function Row({ children, index, ...props }) {
    return React.Children.map(children, (child, columnIndex) =>
        child?.type === Cell
            ? React.cloneElement(child, {
            ...props,
            rowIndex: index,
            columnIndex,
        })
            : child 
    );
}

Row.propTypes = {
    index: px.number,
    children: px.node,
};

export function Cell({ children, rowIndex, columnIndex, header, className, sticky, style: baseStyle, ...props }) {
    const style = useMemo(
        () => ({
            ...baseStyle,
            gridColumn: columnIndex + 1,
            gridRow: rowIndex + 1,
        }),
        [baseStyle, rowIndex, columnIndex]
    );

    return (
        <div
            className={cx(
                'Grid__cell',
                rowIndex % 2 === 1 ? 'Grid__cell--odd' : 'Grid__cell--even',
                header && 'Grid__cell--header',
                sticky && `Grid__cell--sticky-${sticky}`,
                className
            )}
            style={style}
            {...props}
        >
            {children}
        </div>
    );
}

Cell.propTypes = {
    rowIndex: px.number,
    columnIndex: px.number,
    children: px.node,
    header: px.bool,
    className: px.string,
    style: px.object,
    sticky: px.oneOf(['left', 'right']),
};
