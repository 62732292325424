import * as React from 'react';
import px from 'prop-types';
import { Product } from 'Common/types';
import { Url } from 'Common/utils';
import * as PRODUCT from '~config/product';
import ProductPrice from 'Common/components/product/ProductPrice';
import ProductRating from 'Common/components/product/ProductRating';
import { Translation } from 'Common/components/localization';
import { ProductInfo } from 'Common/models';

export default function RecommendedProductCard({ product, onOpenQuick, onSelectProduct, index }) {
    const productLink = React.useMemo(
        () => (product ? `${product.ContentUrl}?prid=${product.ProductRecommendationId.toString()}` : null),
        [product]
    );

    const productReviewProviderEntityId = React.useMemo(
        () => (product ? product.ReviewProviderEntityId : null),
        [product]
    );

    const productUrlIsForSameSite = React.useMemo(
        () =>
            productLink ? (Url.isAbsolute(productLink) ? new URL(productLink).host === location.host : true) : false,
        [productLink]
    );

    const anchorTarget = React.useMemo(() => (productUrlIsForSameSite ? '_self' : '_blank'), [productUrlIsForSameSite]);
    const anchorRel = React.useMemo(
        () => (productUrlIsForSameSite ? undefined : 'noopener noreferrer'),
        [productUrlIsForSameSite]
    );

    return (
        <div className="ProductRecommendations__Product px-2">
            <div className="d-flex align-items-center flex-column">
                <a
                    href={productLink}
                    target={anchorTarget}
                    rel={anchorRel}
                    onClick={onSelectProduct(product, index)}
                    className="subheader px-2 py-3"
                    aria-label={product.DisplayName}
                >
                    <img
                        className="ProductRecommendations__Product__image fit-image-main"
                        src={`${product.DefaultImageUrl}?format=png&height=250&width=381${
                            PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                        }`}
                        alt={product.DisplayName}
                    />
                </a>
                <div className="px-3">
                    <ProductRating
                        rating={product.Rating}
                        reviewProviderEntityId={productReviewProviderEntityId}
                        center
                        slimView
                    />
                    <a
                        href={productLink}
                        target={anchorTarget}
                        rel={anchorRel}
                        onClick={onSelectProduct(product, index)}
                        className="subheader px-2 py-3"
                    >
                        {product.DisplayName}
                    </a>
                    <ProductPrice
                        className="pt-2 pb-3 font-weight-bolder"
                        priceInfo={{ price: product.Price, useQtySalePrice: false }}
                        quantity={1}
                    />
                    <div className="ProductRecommendations__Product__quickview p-0">
                        <p onClick={onOpenQuick(product)} className="paragraph-3 p-0 font-weight-bold">
                            <i className="fas fa-eye mr-1" aria-hidden="true" />
                            <Translation
                                id={
                                    ProductInfo.isDisplayOnly(product)
                                        ? 'Commerce.CatalogNode.Card.DisplayOnlyQuickView'
                                        : 'Commerce.CatalogNode.Card.QuickView'
                                }
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

RecommendedProductCard.propTypes = {
    product: Product,
    onOpenQuick: px.func,
    onSelectProduct: px.func,
    index: px.number,
};
