import { useMemo } from 'react';

const selectPrimary = (x) => x.IsDefault || x.MediaGroup === 'Primary';
const selectImage = (x) => x.MediaType === 'image';
const selectPrimaryImage = (x) => selectPrimary(x) && selectImage(x);
const first = (arr) => arr && arr[0];
const parseUrl = (url) => {
    if (url) {
        const [, ext] = url.match(/\.([a-z]{3,4})(?:\?.*)?$/i);

        return { MediaType: 'image', Url: url, FileType: ext };
    }
    return undefined;
};

export default function useProductDetails(variant, product, opts = {}) {
    const { useFacets = false, includeDisplayOnlyFacets = false, salePrice = null } = useMemo(() => opts, [opts]);
    const attributes = useMemo(
        () =>
            (!product?.Facets || !useFacets
                ? variant?.Attributes?.filter((x) => x.IsSpecification)
                : includeDisplayOnlyFacets
                ? product?.Facets
                : product?.Facets?.filter((f) => !/^display/i.test(f.SelectorControlType))) || [],
        [useFacets, includeDisplayOnlyFacets, product, variant]
    );

    const details = useMemo(
        () => ({
            badges: [...new Set(variant?.MarketingBadges?.map((badge) => badge.toUpperCase()))],
            mainMedia:
                variant?.GalleryMedia?.find(selectPrimary) ??
                first(variant?.GalleryMedia) ??
                product?.GalleryMedia?.find(selectPrimary) ??
                first(product?.GalleryMedia),

            mainMediaImage:
                variant?.GalleryMedia?.find(selectPrimaryImage) ??
                variant?.GalleryMedia?.find(selectImage) ??
                parseUrl(variant?.DefaultImageUrl) ??
                product?.GalleryMedia?.find(selectPrimaryImage) ??
                product?.GalleryMedia?.find(selectImage) ??
                parseUrl(product?.DefaultImageUrl),
            priceInfo: {
                price: variant?.Price,
                salePrice: typeof salePrice === 'number' ? salePrice : variant?.Price.SalePrice.Amount,
                useQtySalePrice:
                    variant?.Price.QtySalePrices != null && Object.keys(variant?.Price.QtySalePrices).length > 1,
            },
            stockStatus: {
                code: variant?.Code,
                statusDisplayName: variant?.StockStatusLabel,
                hasStock: variant?.StockStatus === 'InStock',
            },
            attributes: attributes.map(({ AttributeDisplayName, DisplayName, SelectorControlType }) => ({
                name: DisplayName || AttributeDisplayName,
                value:
                    variant?.Attributes.find((x) => x.AttributeDisplayName === AttributeDisplayName)?.Values?.join(
                        ', '
                    ) ?? '',
                isDisplayOnly: /^display/i.test(SelectorControlType),
            })),
        }),
        [variant, product, attributes, salePrice]
    );

    return details;
}
