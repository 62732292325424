import * as types from './types';
import * as api from './api';
import { getOrderPagination, getUserEmail } from './selectors';
import initialState from './state';
import { logger } from 'Common/core';
import * as actions from './actions.js';

export function setUserEmail(email) {
    return { type: types.SET_USER_EMAIL, payload: email };
}

export function setCustomerOrders(orders) {
    return {
        type: types.SET_CUSTOMER_ORDERS,
        payload: orders,
    };
}

export function setCustomerOrder(order) {
    return {
        type: types.SET_CUSTOMER_ORDERS,
        payload: { orders: [order] },
    };
}

export function clearCustomerOrders() {
    return {
        type: types.SET_CUSTOMER_ORDERS,
        payload: initialState.customerOrders,
    };
}

export function setCustomerMessage(
    message,
    success = true,
    type = null,
    position = null,
    translationParams = {},
    ts = Date.now()
) {
    return {
        type: types.SET_CUSTOMER_MESSAGE,
        payload: { message, success, ts, type, position, translationParams },
    };
}

export function setCustomerError(message = '', type = 'error', translationParams = {}, ts = Date.now()) {
    return {
        type: types.SET_CUSTOMER_ERROR,
        payload: { message, ts, type, translationParams },
    };
}

// actions.setCustomerError must be used for unit testing
export function clearCustomerError() {
    return actions.setCustomerError('', null, {}, 0);
}

// addresses

export function setCustomerAddresses(addresses) {
    return {
        type: types.SET_CUSTOMER_ADDRESSES,
        payload: addresses,
    };
}

export function deleteCustomerAddress(id) {
    return {
        type: types.DELETE_CUSTOMER_ADDRESS,
        payload: id,
    };
}

export function fetchAddresses() {
    return async (dispatch) => {
        const response = await api.getCustomerAddresses();

        if (response?.success) {
            dispatch(actions.setCustomerAddresses(response.customerAddresses));
        }
    };
}

export function saveCustomerAddress(
    address,
    { handleError = false, defaultMessage = 'Commerce.Order.Cart.AddressVerification.FailureMessage' } = {}
) {
    return async (dispatch) => {
        let message = defaultMessage;

        if (handleError) {
            dispatch(actions.clearCustomerError());
        }

        try {
            const response = await api.saveCustomerAddress(address);

            if (response?.success) {
                if (response.customerAddresses) {
                    dispatch(actions.setCustomerAddresses(response.customerAddresses));
                }
                return { success: true };
            }

            throw new Error(response?.message ?? message);
        } catch (e) {
            message = e?.message || message;
            if (handleError) dispatch(actions.setCustomerError(message));
        }

        return { success: false, error: message };
    };
}

export function removeCustomerAddress(id, { handleError = true, defaultMessage = '' } = {}) {
    return async (dispatch) => {
        let message = defaultMessage;

        if (handleError) {
            dispatch(actions.clearCustomerError());
        }

        try {
            const response = await api.deleteCustomerAddress(id);

            if (response?.success) {
                dispatch(actions.deleteCustomerAddress(id));
                return { success: true };
            }
            throw new Error(response?.message);
        } catch (e) {
            message = e?.message || message;
            if (handleError) dispatch(actions.setCustomerError(message));
        }

        return { success: false, error: message };
    };
}

// gift cards

export function setCustomerGiftCards(giftCards) {
    return {
        type: types.SET_CUSTOMER_GIFT_CARDS,
        payload: giftCards,
    };
}

export function addCustomerGiftCard(card) {
    return {
        type: types.ADD_CUSTOMER_GIFT_CARD,
        payload: {
            card,
        },
    };
}

export function deleteCustomerGiftCard(id) {
    return {
        type: types.DELETE_CUSTOMER_GIFT_CARD,
        payload: { id },
    };
}

export function fetchGiftCards() {
    return async (dispatch) => {
        const response = await api.getCustomerGiftCards();

        if (response?.success) {
            dispatch(actions.setCustomerGiftCards(response.giftCards));
        }
    };
}

export function saveCustomerGiftCard(card) {
    return async (dispatch) => {
        const response = await api.saveCustomerGiftCard(card);

        if (response) {
            if (response.success) {
                dispatch(actions.addCustomerGiftCard(response.giftCard));
            } else {
                dispatch(actions.setCustomerMessage(response.message, false, types.ADD_CUSTOMER_GIFT_CARD, 'body'));
            }
        }

        return response;
    };
}

export function removeCustomerGiftCard(id) {
    return async (dispatch) => {
        const response = await api.deleteCustomerGiftCard(id);

        if (response) {
            dispatch(actions.deleteCustomerGiftCard(id));
        }
    };
}

// saved payment methods

export function setCustomerPaymentMethods(cards) {
    return {
        type: types.SET_CUSTOMER_PAYMENT_METHODS,
        payload: cards,
    };
}

export function fetchPaymentMethods() {
    return async (dispatch) => {
        const cards = await api.getCustomerPaymentMethods();

        if (cards) {
            dispatch(actions.setCustomerPaymentMethods(cards));
        }
    };
}

export function removeCustomerPaymentMethod(id) {
    return async (dispatch) => {
        dispatch(actions.clearCustomerError());
        const cards = await api.deleteCustomerPaymentMethod(id);

        if (cards) {
            dispatch(actions.setCustomerPaymentMethods(cards));
        }
    };
}

export function addCustomerPaymentMethod(data, paymentMethod) {
    return async (dispatch) => {
        dispatch(actions.clearCustomerError());
        try {
            const response = await api.addCustomerPaymentMethod({
                PaymentType: paymentMethod.PaymentType,
                SystemName: paymentMethod.SystemName,
                ...data,
            });

            if (response?.success) {
                dispatch(actions.setCustomerPaymentMethods(response.creditCards));
            } else {
                dispatch(actions.setCustomerError(response?.message));
            }
        } catch (e) {
            dispatch(actions.setCustomerError(e?.message ?? e));
        }
    };
}

export function setDefaultCustomerPaymentMethod(id) {
    return async (dispatch) => {
        dispatch(actions.clearCustomerError());
        const cards = await api.setDefaultCustomerPaymentMethod(id);

        if (cards) {
            dispatch(actions.setCustomerPaymentMethods(cards));
        }
    };
}

export function fetchOrdersByEmail(page) {
    return async (dispatch, getState) => {
        const s = getState();
        const orderPagination = getOrderPagination(s);
        const queryOrderCount = orderPagination.orderCount;
        const queryOrderIndex = (page - 1) * queryOrderCount;
        const email = getUserEmail(s);
        const response = await api.getOrderHistoryByEmail(email, queryOrderIndex, queryOrderCount);

        if (response) {
            dispatch(
                actions.setCustomerOrders({
                    orders: response.OrderHistory.Orders,
                    orderPage: page,
                })
            );
        }
    };
}

export function queryOrder(orderNumber, orderEmail = null) {
    return async (dispatch, getState) => {
        const response = await api.queryOrder(orderEmail || getUserEmail(getState()), orderNumber);

        if (response) {
            dispatch(actions.setCustomerOrders({ orders: [response.orderDetail] }));
        }
    };
}

export function submitReturnRequest(returnRequest) {
    return async (dispatch) => {
        const response = await api.submitReturnRequest(returnRequest);

        if (response) {
            dispatch({
                type: types.SET_CUSTOMER_ORDER_RMARETURNDATA,
                payload: {
                    rmaRequestSuccess: response.success,
                    rmaRequestMessage: response.message,
                    rmaRequestReference: response.rmaRequestReference,
                },
            });
        }
    };
}

// notification subscription
export function setUserNotificationSubscriptions(payload) {
    return {
        type: types.SET_CUSTOMER_USER_NOTIFICATION_SUBSCRIPTIONS,
        payload: payload,
    };
}

// Notification Subscription

export function getNotificationSubscriptionList() {
    return async (dispatch) => {
        const response = await api.getCustomerNotificationSubscriptionList();

        if (response?.UserNotificationSubscriptions) {
            dispatch(actions.setUserNotificationSubscriptions(response.UserNotificationSubscriptions));
        } else {
            logger.warn(
                `Search request failed: ${response?.errors ? response.errors[0] : response?.message || response}`
            );
        }
    };
}

export function unsubscribeNotificationSubscriptionById(id) {
    return async (dispatch) => {
        const response = await api.unsubscribeNotificationSubscriptionById(id);

        if (response?.Success) {
            dispatch({
                type: types.REMOVE_CUSTOMER_USER_NOTIFICATION_SUBSCRIPTION,
                payload: id,
            });
        } else {
            logger.warn(
                `Search request failed: ${response?.errors ? response.errors[0] : response?.message || response}`
            );
        }
    };
}

// Notification

export function subscribteToNotification(email, code) {
    return async (dispatch) => {
        const response = await api.subscribe(email, code);

        if (response) {
            dispatch({
                type: types.SET_CUSTOMER_NOTIFICATION,
                payload: response,
            });
        }
    };
}

// Mailchimp Subscription

export function subscribeToMailchimp(email, listId, firstName, lastName, birthMonth, birthDate, tags) {
    return async (dispatch) => {
        const response = await api.mailchimpSubscribe(email, listId, firstName, lastName, birthMonth, birthDate, tags);

        if (response) {
            dispatch({
                type: types.SET_CUSTOMER_MAILCHIMP,
                payload: response,
            });
        }
    };
}
