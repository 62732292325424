import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useTranslation, useViewport } from 'Common/hooks';
import { Product } from 'Common/types';
import ProductTable from './ProductTable';
import ProductTableMobile from './ProductTableMobile';

export default function ProductSpecifications({
    className,
    forceMobile = false,
    gtmListValue,
    id,
    statusTooltip,
    isUserAuthenticated,
    labelledBy = id ? id.replace(/content/g, 'tab') : undefined,
    onAddItemToCart,
    orderLastUpdate,
    productDefinitions,
    productInfo,
    specifications,
    specsTableBreakpoint,
    totalQty = 0,
}) {
    const viewport = useViewport();
    const [isLoading, setIsLoading] = React.useState(false);
    const addToCartLabel = useTranslation('Form.Button.AddToCart.Label');

    React.useEffect(() => {
        setIsLoading(false);
    }, [orderLastUpdate]);

    return (
        <div
            id={id}
            role="tabpanel"
            aria-labelledby={labelledBy}
            className={cx('ProductSpecifications Table tab-pane fade show', className)}
        >
            {forceMobile || viewport.is.mobile ? (
                <ProductTableMobile
                    addToCartLabel={addToCartLabel}
                    gtmListValue={gtmListValue}
                    isLoading={isLoading}
                    isUserAuthenticated={isUserAuthenticated}
                    itemStatusTooltip={statusTooltip}
                    onAddItemToCart={specifications.showAddToCartButton ? onAddItemToCart : undefined}
                    productInfo={productInfo}
                    specsTableBreakpoint={specsTableBreakpoint}
                    tableData={specifications.tableDataMobile}
                    totalQty={totalQty}
                />
            ) : (
                <ProductTable
                    addToCartLabel={addToCartLabel}
                    gtmListValue={gtmListValue}
                    isLoading={isLoading}
                    isUserAuthenticated={isUserAuthenticated}
                    itemStatusTooltip={statusTooltip}
                    onAddItemToCart={specifications.showAddToCartButton ? onAddItemToCart : undefined}
                    productInfo={productInfo}
                    tableData={specifications.tableDataDesktop}
                    totalQty={totalQty}
                />
            )}
            {productDefinitions?.length ? (
                <div className="col-12 px-0 mt-3">
                    {productDefinitions.map((definition) => (
                        <p key={definition} className="light-sm">
                            {definition}
                        </p>
                    ))}
                </div>
            ) : null}
        </div>
    );
}

ProductSpecifications.propTypes = {
    className: px.string,
    forceMobile: px.bool,
    gtmListValue: px.string,
    id: px.string,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    statusTooltip: px.oneOf([false, true, undefined, null, 'top', 'bottom']),
    labelledBy: px.string,
    onAddItemToCart: px.func,
    orderLastUpdate: px.number,
    productDefinitions: px.arrayOf(px.string),
    productInfo: Product,
    specifications: px.shape({
        tableDataMobile: px.shape({
            columns: px.arrayOf(
                px.shape({
                    name: px.string,
                    hasStock: px.bool,
                })
            ),
            rows: px.arrayOf(
                px.shape({
                    values: px.arrayOf(
                        px.shape({
                            id: px.string,
                            values: px.arrayOf(px.string),
                        })
                    ),
                    label: px.string,
                })
            ),
        }),
        tableDataDesktop: px.shape({
            columns: px.arrayOf(px.string),
            rows: px.arrayOf(
                px.shape({
                    values: px.arrayOf(
                        px.shape({
                            id: px.string,
                            values: px.arrayOf(px.string),
                            hasStock: px.bool,
                            gtaPayload: px.object,
                        })
                    ),
                    label: px.string,
                })
            ),
        }),
        showAddToCartButton: px.bool,
    }),
    specsTableBreakpoint: px.number,
    totalQty: px.number,
};
