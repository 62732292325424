import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Formatter } from 'Common/utils';

/**
 *
 * Displays product price based on optional params of quantity and range.
 * Displays sale price if applicable.
 * Optionally transforms backend product price properties.
 */
export default function ProductPrice({
    className,
    priceInfo,
    quantity,
    defaultPrice,
    noRanges = false,
    multiplyByQuantity = false,
    skipPriceTransform = false,
}) {
    const price = React.useMemo(
        () =>
            priceInfo ? getProductPrice(priceInfo, quantity, multiplyByQuantity, !noRanges, skipPriceTransform) : null,
        [priceInfo, quantity, noRanges, multiplyByQuantity, skipPriceTransform]
    );

    return (
        <div className={cx('ProductPrice', className)}>
            <p className={cx(price?.reducedPrice && 'strike', 'mb-0')}>{price?.price || defaultPrice}</p>
            {price?.reducedPrice ? (
                <p className="ProductPrice__reduced-price reduced-price mb-0">{price?.reducedPrice}</p>
            ) : null}
        </div>
    );
}

function getQuantitySalePrice(quantitySalePrices, quantity) {
    const quantityOptions = Object.keys(quantitySalePrices);
    let salePrice = quantitySalePrices[quantityOptions[0]];

    for (const option of quantityOptions.slice(1)) {
        if (quantity < parseInt(option, 10)) {
            break;
        }
        salePrice = quantitySalePrices[option];
    }

    return salePrice.Amount;
}

function getProductPrice(priceInfo, quantity, multiplyByQuantity, useRange, skipPriceTransform) {
    if (skipPriceTransform) return priceInfo;

    const factor = multiplyByQuantity ? quantity : 1;
    const salePrice = priceInfo.useQtySalePrice
        ? getQuantitySalePrice(priceInfo.price.QtySalePrices, quantity)
        : priceInfo.salePrice;

    const qtySale = priceInfo.useQtySalePrice ? salePrice * factor : 0;

    let retail = useRange
        ? [priceInfo.price?.MinListPrice.Amount * factor, priceInfo.price?.MaxListPrice.Amount * factor]
        : priceInfo.price.ListPrice.Amount * factor;

    let sale = useRange
        ? [priceInfo.price?.MinSalePrice.Amount * factor, priceInfo.price?.MaxSalePrice.Amount * factor]
        : salePrice * factor;

    if (qtySale && (Array.isArray(sale) ? Math.max(...sale) : sale) > qtySale) {
        sale = useRange ? [sale[0], qtySale] : qtySale;
    }

    if (useRange) {
        retail = quantity ? (!retail[1] || retail[0] === retail[1] ? retail[0] : retail) : retail;
        sale = quantity ? (!sale[1] || sale[0] === sale[1] ? sale[0] : sale) : sale;
    }

    const retailStr = retail ? Formatter.currency(retail, priceInfo.price?.ListPrice.Currency) : '';
    const saleStr =
        (Array.isArray(sale) && sale.every((s) => typeof s === 'number' && s >= 0)) ||
        (typeof sale === 'number' && sale >= 0)
            ? Formatter.currency(sale, priceInfo.price?.SalePrice.Currency)
            : '';

    const maxSale = Array.isArray(sale) ? sale[1] || sale[0] : sale;
    const maxRetail = Array.isArray(retail) ? retail[1] || retail[0] : retail;

    if (!maxSale && !maxRetail) return null;
    if (retailStr === saleStr) return { price: saleStr };
    if (maxSale < 0) return { price: retailStr };
    return { price: retailStr, reducedPrice: saleStr };
}

ProductPrice.propTypes = {
    className: px.string,
    defaultPrice: px.string,
    priceInfo: px.object,
    quantity: px.oneOfType([px.string, px.number]),
    multiplyByQuantity: px.bool,
    noRanges: px.bool,
    skipPriceTransform: px.bool,
};
