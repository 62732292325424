import { Api } from 'Common/utils';

export function trackProductQuickviewClick(code) {
    return Api.POST('/tracking/trackProductQuickviewClick', {
        code,
    });
}

/**
 * @param {string|string[]} codes
 */
export function fetchProductDetails(codes) {
    const codeArr = typeof codes === 'string' ? [codes] : codes;
    const params = new URLSearchParams();

    for (const code of codeArr) params.append('code', code);

    return Api.GET('/product', params);
}

export function getRelatedProducts(code) {
    return Api.GET(`/product/${code}/recommendedProducts`);
}
