import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Translation } from 'Common/components/localization';

export default function ProductAttributes({ activeChild, attributes, onAddChildToCart, productChildren }) {
    const [selectedChild, setSelectedChild] = React.useState(undefined);

    React.useEffect(() => {
        setSelectedChild(activeChild);
    }, [activeChild, setSelectedChild]);

    if (productChildren?.length > 1) {
        //comparison table for multiple variants
        return (
            <div className="row mb-2">
                <div className="col-12">
                    <div className="mw-100 overflow-auto">
                        <table className="ProductSpecsTable">
                            <thead>
                                <tr>
                                    <th></th>
                                    {productChildren.map((child) => (
                                        <th
                                            key={child.ContentLink}
                                            className={cx(
                                                'p-2 text-center product-cell',
                                                selectedChild === child && 'selected'
                                            )}
                                            onClick={() => setSelectedChild(child)}
                                        >
                                            <div>
                                                {child.DefaultImageUrl ? (
                                                    <img
                                                        className="img-fluid"
                                                        src={child.DefaultImageUrl}
                                                        alt={child.DisplayName}
                                                    />
                                                ) : null}
                                            </div>
                                            <div>{child.DisplayName}</div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {attributes.map((attribute) => (
                                    <tr key={attribute.AttributeName}>
                                        <td className="font-weight-bold p-2 text-left">
                                            {attribute.AttributeDisplayName}
                                        </td>
                                        {productChildren.map((child) => (
                                            <td
                                                key={child.ContentLink}
                                                className={cx(
                                                    'p-2 text-center  product-cell',
                                                    selectedChild === child && 'selected'
                                                )}
                                                onClick={() => setSelectedChild(child)}
                                            >
                                                {child.Attributes.find(
                                                    (childAttr) => childAttr.AttributeName === attribute.AttributeName
                                                )?.Values[0] || null}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    {productChildren.map((child) => (
                                        <td
                                            key={child.ContentLink}
                                            className={cx(
                                                'p-2 text-center add-to-cart-cell',
                                                selectedChild === child && 'selected'
                                            )}
                                            onClick={() => setSelectedChild(child)}
                                        >
                                            {child.StockStatus === 'OutOfStock' ? (
                                                <h6>
                                                    <Translation id="Commerce.Product.ProductOutOfStock" />
                                                </h6>
                                            ) : (
                                                <button className="btn btn-outline" onClick={onAddChildToCart(child)}>
                                                    <Translation id="Form.Button.AddToCart.Label"></Translation>
                                                </button>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    if (productChildren?.length === 1) {
        //specs for a singular variant
        const specs = productChildren[0].Attributes.filter((attr) => attr.IsSpecification);

        return (
            <dl className="ProductSpecsSingle row mb-2">
                {specs.map((spec) => (
                    <div key={spec.AttributeName} className="col-6 col-md-4 col-lg-2 text-center">
                        <dt>{spec.AttributeDisplayName}</dt>
                        <dd>{spec.Values[0]}</dd>
                    </div>
                ))}
            </dl>
        );
    }

    return null;
}

ProductAttributes.propTypes = {
    activeChild: px.object,
    attributes: px.arrayOf(px.object),
    onAddChildToCart: px.func,
    productChildren: px.arrayOf(px.object),
};
