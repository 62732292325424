import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as PRODUCT from '~config/product';
import { useSelector } from 'react-redux';
import { LinkItem } from 'Common/components/ui';
import { Translation } from 'Common/components/localization';
import { LinkItem as LinkItemType } from 'Common/types';
import { product } from '~features';

export default function DetailsLinks({ className, retailSearch, onAddItemToCompare = null }) {
    const qty = useSelector(product.selectors.getQuantity);
    const currentItemStatus = useSelector(product.selectors.getCurrentItemStatus);

    const onClickCompare = React.useCallback(
        (sku) => (event) => {
            event.preventDefault();
            onAddItemToCompare(sku);
        },
        [onAddItemToCompare]
    );

    return (
        <div className={cx('DetailsLinks', 'row justify-content-lg-start justify-content-center', className)}>
            {retailSearch ? (
                <LinkItem className="paragraph-3 mx-3 mr-sm-2 ml-sm-0" link={retailSearch}>
                    <i className="fa fa-search mr-2" aria-hidden="true"></i>
                    <strong>
                        <Translation id="Commerce.Product.Link.RetailSearch.Label" />
                    </strong>
                </LinkItem>
            ) : null}
            {onAddItemToCompare ? (
                <a
                    className="ProductCompareLabel paragraph-3 mx-3 mr-sm-2"
                    href="#"
                    onClick={onClickCompare(currentItemStatus.code)}
                >
                    <i className="fa fa-chart-bar"></i>{' '}
                    <strong>
                        <Translation id="Commerce.Product.Compare" />
                    </strong>
                </a>
            ) : null}
        </div>
    );
}

DetailsLinks.propTypes = {
    className: px.string,
    retailSearch: LinkItemType,
    onAddItemToCompare: px.func,
};
