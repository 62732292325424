import $ from 'jquery';

const ZERO_REVIEW_LABEL = '0 Reviews';

export default class RatingsController {
    static verifyStarRating() {
        let i = 1;

        //This waits for the Yotpo widget to load before setting the value of the label.
        $('body').on('DOMNodeInserted', '*:not([id]).yotpo-display-wrapper', function yotpoDisplayWrapperAdded() {
            $(this).attr('id', `wrapper-${i}`);

            if ($(this).has('.standalone-bottomline').length) {
                //If the bottom line widget is used in conjunction with a product using the main widget it
                //will return a value.
                //By default, if the product has 0 reviews, the Yotpo bottomline returns the text
                //"Write a review" next to the empty stars. This changes the text to 0 Reviews.
                //If the product has reviews the write-review-btn-hidden class will not exist.
                $(this)
                    .children('.standalone-bottomline')
                    .children('.yotpo-bottomline')
                    .children('.write-review-btn-hidden')
                    .text(ZERO_REVIEW_LABEL);
            } else {
                const elem = $(this);

                //If the bottom line widget is NOT used in conjunction with a product using the main widget it
                //will return a value only if it has reviews.  So, to show the stars we need to append the
                //following snippet of code to show the empty stars on the product.
                //Replaced
                elem.append(`<div class="standalone-bottomline" data-source="default" tabindex="-1">
                             <div class="yotpo-bottomline pull-left star-clickable" tabindex="0">
                             <span class="yotpo-stars">
                             <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                             <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                             <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                             <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                             <span class="yotpo-icon yotpo-icon-empty-star rating-star pull-left"></span>
                             </span>
                             <div class="yotpo-clr"></div>
                             </div>
                             <div class="yotpo-clr"></div>
                             </div>`);
            }
            i += 1;

            //When the Review/Question split Yotpo widget is used this span holds the value for the review count.
            const reviewQaLabelsContainer = $(this).find('.reviews-qa-labels-container').text();
            //When the tabbed Yotpo widget is used this span holds the value for the review count.
            const reviewQaLabel = $(this).find('.reviews-qa-label').text();

            if (reviewQaLabelsContainer.length) {
                $('.reviewCount').text(reviewQaLabelsContainer);
            } else {
                if (reviewQaLabel.length) {
                    $('.reviewCount').text(reviewQaLabel);
                }
            }
        });
    }
}
