import { Api } from 'Common/utils';
import { getCreditCardPaymentFromForm } from '../order/api';

// addresses

export function getCustomerAddresses() {
    return Api.GET('/customer/getCustomerAddresses');
}

export function saveCustomerAddress(address) {
    return Api.PUT(
        '/customer/saveAddress',
        {
            ...address,
            IsShipping: true,
        },
        { safe: false }
    );
}

export function deleteCustomerAddress(id) {
    return Api.DELETE(`/customer/deleteAddress`, `id=${id}`, { safe: false });
}

// gift cards

export function getCustomerGiftCards() {
    return Api.GET('/customer/getCustomerGiftCards');
}

export function deleteCustomerGiftCard(id) {
    return Api.DELETE(`/customer/deleteGiftCard/${id}`);
}

export function saveCustomerGiftCard(card) {
    return Api.PUT('/customer/saveGiftCard', {
        ...card,
    });
}

// payment methods

export function getCustomerPaymentMethods() {
    return Api.GET('/customer/GetCustomerCreditCards');
}

export function deleteCustomerPaymentMethod(id) {
    return Api.DELETE(`/customer/deleteCreditCard/${id}`);
}

export async function addCustomerPaymentMethod(data) {
    const response = await getCreditCardPaymentFromForm(data);

    if (response?.success) {
        return Api.POST(`/customer/addCreditCard`, response.creditCard, { safe: false });
    } else {
        return response;
    }
}

export function setDefaultCustomerPaymentMethod(id) {
    return Api.PUT(`/customer/markCreditCardAsDefault/${id}`);
}

// order history

export function getOrderHistoryByEmail(email, index, orderCount) {
    return Api.GET(`/order/GetOrderHistoryForEmail?orderEmail=${email}&startIndex=${index}&batchSize=${orderCount}`);
}

export function queryOrder(email, orderNumber) {
    return Api.POST(`/order/QueryOrder`, {
        OrderEmail: email,
        OrderNumber: orderNumber,
    });
}

export function submitReturnRequest(returnRequest) {
    return Api.POST('/order/SubmitReturnRequest', {
        ...returnRequest,
    });
}

// Notifications
export function getCustomerNotificationSubscriptionList() {
    return Api.GET('/notificationsubscription/GetCurrentUserNotificationSubscriptions');
}

export function unsubscribeNotificationSubscriptionById(id) {
    return Api.POST('/notificationsubscription/UnsubscribeById', {
        id,
    });
}

export function subscribe(email, code, token = null) {
    return Api.POST('/notificationsubscription/subscribe', {
        email,
        NotificationId: code,
        NotificationType: 'BackInStock',
        token,
    });
}

// Mailchimp subscribe
export function mailchimpSubscribe(email, listId, firstName, lastName, birthMonth, birthDate, tags) {
    // Mailchimp API expects Birthday to be formatted as 'MM/DD', so we need to ensure user inputs match this formatting
    // User is allowed to just provide the birthMonth value, so we default to 01 for the date if only given a month
    let birthday;
    const month = parseInt(birthMonth, 10);
    const day = parseInt(birthDate ?? '1', 10);

    if (month >= 1 && month <= 12 && day >= 1 && day <= 31) {
        birthday = `${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}`;
    } else {
        birthday = null;
    }

    return Api.POST('/MarketingAutomation/SubscribeToMailingList', {
        SubscriptionEmail: email,
        ListId: listId,
        FirstName: firstName,
        LastName: lastName,
        Birthday: birthday,
        MetaTags: tags,
        ProviderKey: 'Mailchimp',
    });
}
