import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import Icon from './Icon';

const DEFAULT_TIMEOUT_MS = 7000; // 7 seconds

export default function AlertTip({
    className,
    timeout = DEFAULT_TIMEOUT_MS,
    timestamp,
    hidden = false,
    removeFromDomWhenInactive = false,
    children,
    message,
    clickDismiss = false,
    level = 'info',
    placement = 'top',
    ...props
}) {
    const [showTip, setShowTip] = React.useState(false);
    const tipTimer = React.useRef(null);
    const lastTimestamp = React.useRef(0);
    const timeoutRef = React.useRef(timeout);
    const closeTip = React.useCallback(() => {
        clearTimeout(tipTimer.current);
        setShowTip(false);
    }, []);

    React.useEffect(() => {
        timeoutRef.current = timeout;
    }, [timeout]);

    React.useEffect(() => {
        if (timestamp !== lastTimestamp.current) {
            setShowTip(true);
            clearTimeout(tipTimer.current);
            if (timeoutRef.current) tipTimer.current = setTimeout(() => setShowTip(false), timeoutRef.current);
            lastTimestamp.current = timestamp;
        }
    }, [timestamp]);

    React.useEffect(() => {
        return () => {
            clearTimeout(tipTimer.current);
        };
    }, []);

    return !removeFromDomWhenInactive || (!hidden && showTip) ? (
        <div
            className={cx('PureTip', placement, level, className, {
                disabled: !clickDismiss,
                active: !hidden && showTip,
            })}
            onClick={clickDismiss ? closeTip : undefined}
            {...props}
        >
            <div className="PureTip__arrow"></div>
            <div className="PureTip__content">
                {children}
                {message}
                {clickDismiss ? <Icon className="PureTip__content__dismiss" name="times" /> : null}
            </div>
        </div>
    ) : null;
}

AlertTip.propTypes = {
    className: px.string,
    timestamp: px.number,
    children: px.node,
    message: px.string,
    hidden: px.bool,
    level: px.oneOf(['info', 'success', 'warning', 'danger']),
    timeout: px.number,
    placement: px.oneOf(['top', 'right', 'bottom', 'left']),
    clickDismiss: px.bool,
    removeFromDomWhenInactive: px.bool,
};
