import { createSelector } from 'reselect';
import { Formatter, GTM as gtmUtil } from 'Common/utils';
import { getTranslationString } from '../localization/selectors';
import { getProductStore } from '../product/sel';
import * as GTM from 'Common/constants/gtm';
import {
    isCartValidationIssue,
    isPromoCodeValidationIssue,
    isLineItemValidationIssue,
    isNonPaymentValidationIssue,
    isNonLineItemValidationIssue,
} from './helpers';

export const getCartState = (state) => state.order;

export const getLastUpdate = createSelector(getCartState, (state = {}) => state.lastUpdate);

export const getOrderConfig = createSelector(getCartState, ({ config = {} } = {}) => config);

export const isCartUpdating = createSelector(getCartState, (state = {}) => state.isPerformingCartOperation || false);

export const hasCart = createSelector(getLastUpdate, (ts = 0) => !!ts);

export const getCart = createSelector(getCartState, (state = {}) => state.Cart);

export const getTotalQuantity = createSelector(getCart, (cart = {}) => cart.TotalItemQuantity ?? 0);

export const getCartId = createSelector(getCart, (cart = {}) => cart.OrderId);

export const getUserMessage = createSelector(getCartState, (state = {}) => state.userMessage);

export const getUserMessageTimestamp = createSelector(getUserMessage, (message = {}) => message.ts);

export const getHeaderUserMessage = createSelector(getUserMessage, (message = {}) =>
    message.position === 'header' ? message : {}
);

export const getTranslatedHeaderUserMessage = createSelector(
    (state) => state,
    getHeaderUserMessage,
    (state, message = {}) =>
        Formatter.property(getTranslationString(state, message.message) || message.message, message.translationParams)
);

export const findValidationIssue = (predicates) =>
    createSelector(getCartState, (state = {}) =>
        state.ValidationIssues?.find((issue) => predicates.reduce((flag, p) => flag && p(issue), true))
    );

export const findValidationError = (...predicates) =>
    createSelector(
        getCartState,
        findValidationIssue(predicates),
        (state, issue = undefined) =>
            issue && { timestamp: state.lastUpdate, reference: issue.Ref, message: issue.Issue, type: 'error' }
    );

export const getCartValidationError = findValidationError(isCartValidationIssue);
export const getLineItemValidationError = findValidationError(isLineItemValidationIssue);
export const getNonLineItemValidationError = findValidationError(isNonLineItemValidationIssue);
export const getPromoCodeValidationIssue = findValidationError(isPromoCodeValidationIssue);
export const getNonLineItemNorPaymentValidationError = findValidationError(
    isNonLineItemValidationIssue,
    isNonPaymentValidationIssue
);

export const getOrderLink = createSelector(getCart, (cart = {}) => cart.OrderLink);

export const getMarketId = createSelector(getCart, (cart = {}) => cart.MarketId);

export const getPaymentTokens = createSelector(getCartState, (state = {}) => state.paymentTokens);

export const getPaypalPaymentToken = createSelector(getPaymentTokens, (tokens = {}) => tokens.paypal);

export const getCurrency = createSelector(getCart, (cart = {}) => cart.Currency);

export const getCatalogEntities = createSelector(getCartState, (state = {}) => state.CatalogEntity);

export const getCurrencyCode = createSelector(getCart, (cart = {}) => cart.Currency);

export const getChanges = createSelector(getCartState, (state = {}) => state.changes);

export const getQtyChanges = createSelector(getChanges, (changes = []) =>
    changes.filter((change) => change.type === 'qty')
);

export const getAddressChanges = createSelector(getChanges, (changes = []) =>
    changes.find((change) => change.type === 'address')
);

export const getCartForms = createSelector(getCart, (cart = {}) => cart.Forms);

export const getRequiredPaymentAmount = createSelector(getCartForms, (forms = []) => {
    return forms.reduce((total, form) => total + (form.RequiredPaymentAmount || 0), null);
});

export const getShipments = createSelector(getCart, (cart = {}) =>
    cart.Forms?.reduce((acc, form) => acc.concat(form.Shipments), [])
);

export const getOrderEmail = createSelector(getCart, (cart = {}) => cart.OrderEmail);

export const getForm = createSelector(getCartForms, (forms = []) => {
    if (Array.isArray(forms) && forms.length > 0) {
        return forms[0];
    }

    return forms;
});

export const getPaymentMethods = createSelector(getCartForms, (forms = []) => forms[0]?.AvailablePaymentMethods);

export const getCreditCardPaymentMethod = createSelector(getPaymentMethods, (methods = []) =>
    methods.find((p) => p.PaymentType === 'CreditCard')
);

export const getOrderPayments = createSelector(getCartForms, (forms = []) =>
    forms.reduce(
        (acc, form) =>
            acc.concat(
                form.Payments.map((p) => (p.PaymentMethodName.includes('PayPal') ? { ...p, PaymentType: 'PayPal' } : p))
            ),
        []
    )
);

export const getOrderGiftCards = createSelector(getCartForms, (forms = []) =>
    forms[0]?.Payments.filter((payment) => payment.PaymentType && payment.PaymentType === 'GiftCard')
);

// TODO -- refactor when proper data comes through to find non-giftcard payment
export const getBillableOrderPayment = createSelector(getOrderPayments, (payments = []) =>
    payments?.find((payment) => payment.CardType && payment.CardType !== 'GiftCard')
);

export const getFormShipments = createSelector(getForm, (form = {}) => form.Shipments);

export const getAllShipments = createSelector(getCartForms, (forms = []) =>
    forms.reduce(
        (acc, form) =>
            acc.concat(
                form.Shipments.map((s) => ({
                    ...s,
                    Promotions: form.Promotions?.filter((p) => p.ShippingMethodId === s.ShippingMethodId),
                }))
            ),
        []
    )
);

export const getAvailablePaymentTypes = createSelector(getCartForms, (forms = []) =>
    forms.reduce((types, form) => types.concat(form.AvailablePaymentMethods.map((p) => p.PaymentType)), [])
);

export const getSavedShippingAddresses = createSelector(getCart, (cart = {}) => cart.SavedShippingAddresses);

export const getPromoCodes = createSelector(getCartForms, (forms = []) =>
    forms.reduce((acc, form) => acc.concat(form.CouponCodes), [])
);

export const getAllLineItems = createSelector(getAllShipments, (shipments = []) =>
    shipments.reduce((acc, shipment) => acc.concat(shipment.LineItems), [])
);

export const getAllFauxLineItems = createSelector(getAllShipments, (shipments = []) =>
    shipments.reduce((acc, shipment) => acc.concat([...shipment.FauxLineItems, ...shipment.LineItems]), [])
);

export const getCartSummaryData = createSelector(getCart, getAllLineItems, (cart = {}, lineItems = null) => {
    const giftCardPayments =
        cart.Forms?.reduce(
            (payments, form) =>
                payments.concat(
                    form.Payments.filter(({ PaymentType }) => PaymentType === 'GiftCard').map(
                        ({ CardNumber, Amount, Balance }) => ({ CardNumber, Amount, Balance })
                    )
                ),
            []
        ) ?? [];

    const gcTotal = giftCardPayments.reduce((t, p) => t + p.Amount, 0);

    const discounts =
        cart.Forms?.reduce(
            (arr, form) =>
                arr.concat(
                    form.Promotions.reduce((promos, p) => {
                        const existing = promos.find(({ id }) => id === p.PromotionGuid);

                        if (existing) {
                            existing.value += p.SavedAmount;
                        } else {
                            promos.push({
                                value: p.SavedAmount,
                                label: p.Description || p.CouponCode,
                                id: p.PromotionGuid,
                                code: p.CouponCode,
                                type: p.DiscountType,
                            });
                        }

                        return promos;
                    }, [])
                ),
            []
        ) ?? [];

    const orderDiscount = cart.OrderTotals?.OrderDiscountTotal ?? cart.OrderDiscountTotal ?? 0;
    const total = cart.Total ?? (cart.Forms?.[0]?.Total ?? 0) - orderDiscount;

    return {
        subtotal: cart.Forms?.[0]?.SubTotal ?? cart?.SubTotal ?? 0,
        handling: cart.Forms?.[0]?.HandlingTotal ?? cart.HandlingTotal ?? 0,
        shipping: cart.Forms?.[0]?.ShippingTotal ?? cart.ShippingTotal ?? 0,
        shippingSubTotal: cart.OrderTotals?.ShippingSubTotal ?? cart.ShippingSubTotal ?? 0,
        shippingDiscount: cart.OrderTotals?.ShippingDiscountTotal ?? cart.ShippingDiscountTotal ?? 0,
        orderDiscount,
        tax: cart.Forms?.[0]?.TaxTotal ?? cart.TaxTotal ?? 0,

        giftCardPayments,
        giftCardDiscount: gcTotal,
        total,
        discounts,
        totalSansGiftCards: total - gcTotal,
        lineItems,
    };
});

export const getCartSummaryFooterData = createSelector(getCart, (cart = {}) => ({
    orderId: cart.OrderId,
}));

export const getEntityByCode = createSelector(
    (_, code) => code,
    getCatalogEntities,
    (code, map = {}) => map[code]
);

export const getFormShipmentById = createSelector(
    (_, id) => id,
    getFormShipments,
    (id, shipments = []) => shipments.find((s) => s.ShipmentId === id)
);

export const getLineItems = createSelector(getFormShipmentById, (shipment = {}) => shipment.LineItems);

export const getFauxLineItems = createSelector(getFormShipmentById, (shipment = {}) => shipment.FauxLineItems);

export const getAllItemQty = createSelector(getAllLineItems, (items = []) =>
    items.reduce((acc, item) => acc + item.Quantity, 0)
);

export const getAllSubtotal = createSelector(getAllLineItems, (items = []) =>
    items.reduce((acc, item) => acc + item.Quantity * item.SalePrice, 0)
);

export const getLineItemById = createSelector(
    (_, _1, id) => id,
    getLineItems,
    (id, items = []) => items.find((i) => i.LineItemId === id)
);

export const getLineItemByCode = createSelector(
    (_, _1, code) => code,
    getLineItems,
    (code, items = []) => items.find((i) => i.Code === code)
);

export const getFauxLineItemByCode = createSelector(
    (_, _1, code) => code,
    getFauxLineItems,
    (code, items = []) => items.find((i) => i.Code === code)
);

export const getLineItemQtyByCode = createSelector(getLineItemByCode, (item = {}) => item.Quantity || 0);

export const getRemoveCartGTMPayloadByCode = createSelector(
    getProductStore,
    getLineItemByCode,
    getTotalQuantity,
    (products = {}, item = {}, totalQty) =>
        gtmUtil.mapEntityToRemoveCart([products[item.ParentCode]].filter(Boolean), GTM.TAGS.CART, totalQty)
);

export const getRemoveCartFauxGTMPayloadByCode = createSelector(
    getProductStore,
    getFauxLineItemByCode,
    getTotalQuantity,
    (products = {}, item = {}, totalQty) =>
        gtmUtil.mapEntityToRemoveCart([products[item.ParentCode]].filter(Boolean), GTM.TAGS.CART, totalQty)
);

export const getRemoveCartGTMPayload = createSelector(
    getProductStore,
    getAllLineItems,
    getTotalQuantity,
    (products = {}, items = [], totalQty) =>
        gtmUtil.mapEntityToRemoveCart(
            items.map((item) => products[item.ParentCode]).filter(Boolean),
            GTM.TAGS.CART,
            totalQty
        )
);

export const getShipmentsByLineItems = createSelector(getShipments, (shipments = []) =>
    shipments.reduce(
        (acc, shipment) =>
            acc.concat(
                shipment.LineItems.map((item) => ({
                    code: item.Code,
                    quantity: item.Quantity,
                    address: shipment.ShippingAddress,
                }))
            ),
        []
    )
);

export const getAddressByShipment = createSelector(
    (_, shipmentId) => shipmentId,
    getFormShipments,
    (shipmentId, shipments = []) => shipments.find((s) => s.ShipmentId === shipmentId).ShippingAddress
);

export const getShipmentAddressByLineItem = createSelector(
    (_, itemCode) => itemCode,
    getForm,
    (itemCode, form = {}) =>
        form.Shipments.find((shipment) => shipment.LineItems.find((item) => item.Code === itemCode)).ShippingAddress
);
