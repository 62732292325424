import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { UserMessage } from 'Common/components/ui';

export default function QueryStringUserMessage({ removeSearchParams, condition, className, ...props }) {
    const shouldShow = useMemo(() => {
        const params = new URLSearchParams(window.location.search);

        return Object.keys(condition).every((param) => params.get(param) === condition[param]);
    }, [condition]);

    const timestamp = useMemo(() => (shouldShow ? new Date().getTime() : 0), [shouldShow]);

    useEffect(() => {
        if (shouldShow && removeSearchParams && typeof window.history !== 'undefined') {
            const path = window.location.pathname;
            const params = new URLSearchParams(window.location.search);

            Object.keys(condition).forEach((param) => params.delete(param));

            history.replaceState({}, '', [path, params.toString()].filter((x) => x).join('?'));
        }
    }, [shouldShow, removeSearchParams, condition]);

    return <UserMessage className={cx('QueryStringUserMessage', className)} {...props} timestamp={timestamp} />;
}

QueryStringUserMessage.propTypes = {
    ...UserMessage.propTypes,
    /**
     * Query string object to compare to the window's query string. If query string
     * matches all query string parameters in the `condition`, the user message will
     * appear.
     */
    condition: px.objectOf(px.string),
    /**
     * Setting this to `true` will remove all query string parameters that matched
     * the condition, if the condition has been met. This prevents refreshing or
     * pressing the "back" button from showing the message again.
     */
    removeSearchParams: px.bool,
};
