import * as React from 'react';
import px from 'prop-types';
import { Translation } from 'Common/components/localization';
import { Social } from 'Common/components/ui';

export default function ProductShare({ siteId }) {
    return (
        <div className="col-6">
            <Translation id="Social.Share" />: <Social.Share.Facebook className="mr-3" link={window.location} />
            <Social.Share.Twitter className="mr-3" link={window.location} hashtags={siteId} />
            <Social.Share.Email link={window.location} />
        </div>
    );
}

ProductShare.propTypes = {
    siteId: px.string,
};
